import { render, staticRenderFns } from "./InvoiceEdit.vue?vue&type=template&id=36ff53bc&scoped=true"
import script from "./InvoiceEdit.vue?vue&type=script&lang=js"
export * from "./InvoiceEdit.vue?vue&type=script&lang=js"
import style0 from "./InvoiceEdit.vue?vue&type=style&index=0&id=36ff53bc&prod&lang=scss"
import style1 from "./InvoiceEdit.vue?vue&type=style&index=1&id=36ff53bc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ff53bc",
  null
  
)

export default component.exports